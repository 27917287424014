<template>
  <v-row class="mx-0">
    <v-col cols="12">
      <v-toolbar color="transparent mt-1" flat height="48">
        <v-toolbar-title class="ml-n3 subtitle-1">
          Reportes
          <v-icon class="mx-1" color="grey-500" size="22">mdi-chevron-right</v-icon>
          <span class="text-decoration-none grey-500--text font-weight-bold">Resumen de ventas diarias</span>
        </v-toolbar-title>
      </v-toolbar>
    </v-col>
    <v-col>
      <v-card flat>
         <v-card-text class="px-0 py-0">
          <v-skeleton-loader v-if="$store.state.irs.retrieveLoader" transition="fade-transition" type="table"/>
          <template v-else>
            <template v-if="list.length">
              <v-data-table :headers="headers" :items="list" :items-per-page="pagination" disable-sort mobile-breakpoint hide-default-footer>
                <template v-slot:[`item.date`]="{item}">
                  {{item.date | date }}
                </template>
                <template v-slot:[`item.sequence`]="{item}">
                  <span class="grey-500--text font-weight-medium">{{item.sequence}}</span>
                </template>
                <template v-slot:[`item.local_file`]="{item}">
                  <v-btn :href="item.local_file" target="_blank" color="grey-500" outlined><v-icon small>mdi-file</v-icon></v-btn>
                </template>
              </v-data-table>
              <template v-if="showPagination || showAll">
                <v-divider />
                <v-row class="mx-0" align="center">
                  <template v-if="showPagination">
                    <v-col cols="6">
                      <span class="body-2 grey-500--text">{{count}} registros</span>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-pagination v-model="page" :length="totalPages" total-visible="6" />
                    </v-col>
                  </template>
                </v-row>
              </template>
            </template>
            <v-col class="text-center py-8" v-else>
              <v-icon class="d-block" color="grey-500" size="70">mdi-grid-off</v-icon>
              <span class="d-block subtitle-1">No se han informado reportes</span>
            </v-col>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import ListViewMixin from '@/mixins/ListViewMixin'
export default {
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    headers: [
      { text: 'Fecha', value: 'date', width: 200, sortable: true},
      { text: 'Secuencia', value: 'sequence', align: 'end', width: 150, sortable: false },
      { text: 'Track ID', value: 'track_id', align: 'end', width: 100, sortable: false },
      { text: '', value: 'local_file', align: 'end', width: 180, sortable: true}
    ]
  }),
  computed: mapState({
    count: state => state.irs.rvdsCount,
    list: state => state.irs.rvdsList
  }),
  methods: {
    getList () {
      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/rvds'
      })
    }
  }
}
</script>